$gold-color: #eaaf0e;
$card-sky: #5eb3d8;
$purple-color: #9653ca;

.dec2022-sponsor {
  &-StatusHeading {
    font: 500 2.8rem "Roboto", sans-serif;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dec2022-sponsor-heading {
    position: relative;
    font-size: 40px;
    color: #636363;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      width: 80px;
      bottom: -20px;
      height: 2px;
      background-color: #68b993;
    }
  }
  .diamond {
    color: $card-sky;
    .dec2022-horizontal-divider {
      background-color: $card-sky;
    }
  }
  .gold {
    color: $gold-color;
    .dec2022-horizontal-divider {
      background-color: $gold-color;
    }
  }
  .platinum {
    color: $purple-color;
    .dec2022-horizontal-divider {
      background-color: $purple-color;
    }
  }
  .learning {
    color: #999999;
    .dec2022-horizontal-divider {
      background-color: #999999;
    }
  }

  .dec2022-sponsor-wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 1.25rem auto;
    .dec2022-sponsor-card {
      max-width: 350px;
      min-width: 350px;
      padding-block: 40px;
      padding-inline: 20px;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      .dec2022-sponsor-border {
        display: block;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.25);
        padding-block: 35px;
        margin: auto;
        border-radius: 7px;
      }
      .dec2022-sponsor-border img{

        margin: auto;

      }
    }
    .dec2022-sponsor-img {
      width: 70%;
      height: 60px;
      object-fit: contain;
    }
  }
}

.dec2022-Learningsponsor-img {
  width: 80%;
  object-fit: contain;
}

.dec2022-applySponsorText {
  font: 500 1.5rem "Roboto", sans-serif;
  color: $purple-color;
  text-decoration: underline;
  margin: 1rem 0;
}
.dec2022-applySponsorLink {
  border: none;
  color: $purple-color;

  background-color: transparent;
  transition: all 0.05s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
}

.dec2022-applySponsorLink-gold {
  border: none;
  background-color: transparent;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: underline;
  padding-top: 4rem;
  color: #eaaf0e;
  &:hover {
    transform: scale(1.02);
  }
}
.dec2022-horizontal-divider {
  width: 80px;
  height: 2px;
}
@media only screen and (max-width: 576px) {
  .dec2022-sponsor-wrapper {
    width: 100% !important;
  }
}
