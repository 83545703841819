$borderBottomColor: #431282;
$organizerHeadingColor: #636363;

.dec-2023 {
  .prevEvents {
    margin-top: 5rem;
    // margin-bottom: 3rem;
    background: #032952;
    .prevEvents-heading {
      color: $organizerHeadingColor;
      width: fit-content;
      margin: 0 auto;
      border-bottom: 5px solid $borderBottomColor;
    }
    .prevEvents-wrapper {
      width: 40%;
      padding: 0;
      margin: 1.25rem auto;
      transform: scale(1);
      transition: all 0.3s ease-in;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .prevEvents-scroll {
    display: flex;
    flex-wrap: wrap;
  }

  .prevEventImage {
    width: 100%;
    margin: auto;
    // height: 40rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}
@media only screen and (max-width: 768px) {
  .dec-2023 {
    .prevEvents .prevEvents-wrapper {
      width: 90%;
    }
  }
}
