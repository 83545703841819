$agenda-subtitle: #524f4f !important;

.dec-2023 {

  p.MuiTypography-root.MuiTypography-body1.agenda-track-text.text-uppercase.css-1exy8ke-MuiTypography-root {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .agenda-sessions-container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    gap: 0;
  }
  .agenda-paper{
    background-color: #032952;
  }

  button:focus {
    outline: none !important;
  }

  .session-tile {
    display: flex;
    padding-block: 1rem;
    border-radius: 0;
    padding-inline: 4rem;
    box-shadow: none;
    border-bottom: 1px solid #d3d3d3;
}
  .session-data-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .responsive-wrapper {
    display: flex;
    width: 65%;
  }

  .session-time {
    width: 30%;
  }

  .session-title-container {
    display: flex;
    width: 100%;
  }

  .session-title {
    font-weight: 500;
    padding-left: 2rem;
    text-align: left;
  }

  .speaker-container {
    width: 25%;
    flex: 1;
    column-gap: 2rem;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    row-gap: 3rem;
    justify-content: flex-start;
    min-width: 32rem;
    &:last-child {
      width: 32rem;
    }
  }
  .session-speakers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 15rem;
  }
  .speaker-name {
    max-width: 15rem;
  }

  .agenda-paper {
    width: 95%;
    border-radius: 1rem;
  }
  .agenda-head {
    width: 100%;
    height: 10rem;
    border-radius: 1rem !important;
    margin-bottom: 4rem;
  }
  .agenda-track {
    width: 50%;
    border: none;
  }

  .agenda-box {
    flex-direction: column;
    align-items: center;
  }

  // .t1 {
  //   border-radius: 1rem 0 0 1rem !important;
  // }
  .t3 {
    border-radius: 0 1rem 1rem 0 !important;
  }
  .agenda-track-text {
    font-size: 3.2rem;
    display: flex;
    font-weight: 700;
    align-items: center;
    margin: auto;
    gap: 20px;
  }
}

@media screen and (max-width: 980px) {
  .dec-2023 {
    .session-time {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
}
}
@media screen and (max-width: 1280px) {
  .dec-2023 {
    .session-time {
      width: 100%;
      text-align: center;
    }
    .session-time-text {
      margin-left: 0 !important;
    }

    .responsive-wrapper {
      flex-direction: column;
      gap: 0.5rem;
    }

    .session-title-container {
      width: 70%;
    }
    .session-title {
      padding-left: 0;
      // text-align: center;
    }
  }
}


@media screen and (max-width: 980px) {
  .dec-2023 {
    .responsive-wrapper {
      width: 100%;
    }
    .session-title-container {
      width: 100%;
      justify-content: center;
    }
    .session-tile {
      // padding: 2.5rem 1.5rem;
      text-align: center;
      justify-content: center;
    }
    .session-title {
      padding: 0rem !important;
      text-align: center;
      justify-content: center;
    }
    .session-data-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      padding-inline: 3rem;
    }
    .speaker-container {
      width: 100% !important;
      max-width: 35rem;
      margin: auto;
    }
    .session-speakers {
      margin: auto;
      min-width: 0;
    }
    .agenda-track-text {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 515px) {
  .dec-2023 {
    .session-data-container {
      padding-inline: 0rem;
    }
    .speaker-container {
      min-width: 100%;
    }
  }
}
