.prevEvents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  .prevEvents-heading {
    position: relative;
    font-size: 40px;
    color: #636363;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: -40px;
      width: 80px;
      height: 2px;
      background-color: #68b993;
    }
  }
  .prevEvents-wrapper {
    width: 60%;
    padding: 0;
    margin: 1.25rem auto;
    transform: scale(1);
    transition: all 0.3s ease-in;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
  }
}

.prevEvents-scroll {
  display: flex;
}

.prevEventImage {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .prevEvents .prevEvents-wrapper {
    width: 100%;
  }
}
