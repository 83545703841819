$base-color-blue: #0a2f4e;

.dec2022-donate {
  .dec2022-donate-bg {
    text-align: center;
    background-color: $base-color-blue;
    color: #fff;
    .dec2022-donate-heading {
      padding-top: 3rem;
      font-size: 40px;
      font-weight: 400;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: -40px;
        width: 80px;
        height: 2px;
        background-color: #ffffff;
      }
    }
    .dec2022-donate-imgs {
      padding-top: 7rem;
      .dec2022-dono-images {
        height: 100%;
      }
      .dec2022-col {
        padding: 0;
      }
      .dec2022-donate-imgs1,
      .dec2022-donate-imgs2,
      .dec2022-donate-imgs3,
      .dec2022-donate-imgs4 {
        padding: 0;
        img {
          height: 100% !important;
          width: 100%;
        }
      }
    }
    .dec2022-donate-quote {
      font-size: 70px;
      padding-top: 4rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: -40px;
        width: 80px;
        height: 2px;
        background-color: #ffffff;
      }
      .dec2022-open-quotes {
        vertical-align: top;
        height: 45px;
        padding-right: 30px;
      }
      .dec2022-closed-quotes {
        vertical-align: bottom;
        height: 45px;
        padding-left: 30px;
      }
    }
    .dec2022-donate-para-text {
      padding: 7rem 0 4rem 0;
      line-height: 1.9rem;
      font-size: 1.3rem;
      max-width: 60%;
      line-height: 34px;
      margin: auto;
    }
    .dec2022-donation-btn {
      padding-bottom: 4rem;
    }
    .dec2022-donate-btn {
      background-color: #fff;
      border-radius: 40px;
      padding: 15px 70px;
      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
      }
      a {
        color: $base-color-blue;
        text-decoration: none;
        font-size: 32px;
        font-weight: 600;
      }
    }
  }
}

// for only Desktop
// @media only screen and (min-width: 1025px) {
//   .dec2022-donate .dec2022-donate-bg {
//     padding: 2rem 22rem 0 22rem;
//   }
// }
// for only mobiile
@media only screen and (max-width: 768px) {
  .dec2022-donate .dec2022-donate-bg {
    .dec2022-donate-imgs1,
    .dec2022-donate-imgs2,
    .dec2022-donate-imgs3,
    .dec2022-donate-imgs4 {
      padding: 15px 6px;
    }
    .dec2022-donate-para-text {
      max-width: 90%;
      padding: 4rem 0 4rem 0;
      margin: auto;
    }
    .dec2022-donate-quote {
      font-size: 32px;
      font-weight: 500;
      .dec2022-open-quotes {
        height: 15px;
        padding-right: 15px;
      }
      .dec2022-closed-quotes {
        height: 15px;
        padding-left: 15px;
      }
    }
  }
}
