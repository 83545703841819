.dec2022-user-groups {
  background-color: rgba($color: #f5f6f6, $alpha: 0.56);
  &-heading {
    position: relative;
    font-size: 40px;
    color: #636363;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: -40px;
      width: 80px;
      height: 2px;
      background-color: #00a1f1;
    }
  }
  .dec2022-group-wrapper {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    padding-top: 6rem;
    justify-content: center;
    .dec2022-group-card {
      padding: 20px;
      .dec2022-group-border {
        text-decoration: none;
        min-height: 140px;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.25);
        padding: 10px;
        border-radius: 7px;
        .dec2022-group-img {
          padding: 5px 0;
          border-right: 1px solid #00a1f1;
          img {
            height: 80px;
            width: 100px;
            margin: auto;
            object-fit: contain;
          }
        }
        .dec2022-group-name {
          padding: 25px 0 25px 20px;
          span {
            color: #000;
            font-size: 20px;
            // position: relative;
            //     &::before{
            //         content: "";
            //         position: absolute;
            //         left: 0;
            //         margin-left: 20px;
            //         width: 2px;
            //         height: 50px;
            //         background-color: #00A1F1;
            //     }
          }
        }
        .dec2022-group-arrow img {
          height: 50px;
        }
      }
    }
  }
}

// for only Desktop
@media only screen and (min-width: 1025px) {
  .dec2022-user-groups {
    padding: 5rem 8%;
  }
}

// for tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dec2022-user-groups {
    padding: 3rem 2%;
  }
  .dec2022-group-wrapper {
    padding: 6rem 0rem;
    .dec2022-group-card .dec2022-group-border {
      .dec2022-group-arrow img {
        height: 30px;
      }
    }
  }
  .dec2022-user-groups
    .dec2022-group-wrapper
    .dec2022-group-card
    .dec2022-group-border
    .dec2022-group-img
    img {
    height: 42px;
  }
  .dec2022-user-groups
    .dec2022-group-wrapper
    .dec2022-group-card
    .dec2022-group-border {
    min-height: 162px;
  }
}

// // for ipad pro
// @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
//   .dec2022-user-groups .dec2022-group-wrapper .dec2022-group-card .dec2022-group-border {
//     min-height: 170px;
//   }
// }

// for mobile
@media only screen and (max-width: 768px) {
  .dec2022-user-groups {
    padding-top: 30px;

    .dec2022-group-wrapper .dec2022-group-card .dec2022-group-border {
      .dec2022-group-img {
        padding: 5px 0 5px 0px;
        img {
          height: 40px;
          width: 80px;
        }
      }
      .dec2022-group-arrow {
        img {
          height: 30px;
        }
      }
    }
  }
}
