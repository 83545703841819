/*  CSS Preset */

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* font-size: 100% ; */
  font-size: 10px !important;
}

html.dec-2023 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  scroll-behavior: smooth;
}


*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

/* percentage-based heights in the application */
html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Removing built-in form typography styles */

input,
button,
textarea,
select {
  font: inherit;
}

/* Avoid text overflows */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  line-height: 1.2 !important;
}

/* Removing Anchor Tag Underline */
a,
a:hover {
  text-decoration: none;
}

/* Creates a new stacking index */

#root {
  isolation: isolate;
}

/* BootsTrap Fallback */

.col-4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

/* Hiding the ScrollBar */
/* 
body::-webkit-scrollbar {
  display: none;
} */

/* body {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} */
