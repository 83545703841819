$base-color-blue: #1f496c;
$base-color-black: #606060;

.dec2022-countDown-mobile {
  margin: 3rem auto !important;
  padding: 1rem !important;
  background: linear-gradient(0.25turn, #10385a, 1%, white);

  box-shadow: 0px 4px 10px 2px #0000001a;
  border-radius: 40px;
  width: 35% !important;
}

.dec2022-countDown-mobile,
.dec2022-countDown {
  text-align: center;
  padding: 4rem 0;
  margin: auto;
  max-width: 60%;
  .dec2022-countDown-row {
    padding: 35px 0px;
    border-radius: 40px;
    background-color: hsl(0, 0%, 97%);
    // background: linear-gradient(0.25turn, #00a3ff, 1%, white);
    background: linear-gradient(0.25turn, #10385a, 1%, white);
    box-shadow: 0px 4px 10px 2px #0000001a;
    // min-width: 50%;
    // margin: auto;
    .dec2022-timer-date {
      color: $base-color-black;
      font-weight: 600;
      font-size: 2.2vw;
      letter-spacing: 2px !important;
    }
    .dec2022-timer-title {
      color: $base-color-black;
      font-weight: 400;
      font-size: 1.1vw;
    }
    .react-countdown {
      li {
        padding: 0 4%;
        margin: auto;
      }
      .digit {
        font-size: 3vw;
        color: $base-color-black;
      }
      .text {
        font-size: 1.1vw;
        color: $base-color-blue !important;
        text-transform: capitalize !important;
        letter-spacing: 3px !important;
        margin-top: 0vw;
      }
      li:after {
        position: absolute;
        top: -0.25vw;
        right: -7px;
        font-size: 3vw;
        color: #c4c4c4;
      }
    }
  }
}

// for only mobiile
@media only screen and (max-width: 768px) {
  .dec2022-countDown-mobile {
    margin: 3rem auto !important;
    padding: 0 !important;
    background: transparent;
    box-shadow: none;
    border-radius: 40px;
    width: 90% !important;
    max-width: 95%;
  }
  .dec2022-col {
    padding: 0;
  }
  .dec2022-countDown-mobile {
    text-align: center;
    .dec2022-timer-data-row {
      padding-top: 42px;
      padding-bottom: 12px;
    }
    .dec2022-timer-date-mobile {
      color: $base-color-black;
      font-weight: 600;
      font-size: 35px !important;
      letter-spacing: 2px !important;
    }
    .dec2022-timer-title-mobile {
      color: $base-color-black;
      font-weight: 400;
      font-size: 20px;
    }
    .dec2022-timer-mobile {
      padding: 36px 10px 0 10px;
    }
    .react-countdown {
      background: linear-gradient(0.25turn, #10385a, 1%, white);
      padding: 18px 0;
      border-radius: 20px;
      li {
        padding: 0 20px;
        margin: auto;
      }
      .digit {
        font-size: 36px !important;
        color: $base-color-black;
      }
      .dec2022-text {
        font-size: 16px !important;
        color: $base-color-blue !important;
        text-transform: capitalize !important;
        letter-spacing: 3px !important;
        margin-top: -2px !important;
      }
      li:after {
        top: 1px;
        right: -4px;
        font-size: 32px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .dec2022-countDown {
    max-width: 70%;
    .dec2022-countDown-row .react-countdown li {
      padding: 0 14px;
      margin: auto;
    }
    .dec2022-countDown-row {
      .dec2022-timer-date {
        font-size: 24px;
      }
      .dec2022-timer-title {
        font-size: 1.5vw;
      }
    }
    .dec2022-countDown-row .dec2022-react-countdown {
      .-digit {
        font-size: 3vw;
      }
      .dec2022-text {
        font-size: 1.6vw;
      }
    }
  }
  .dec2022-countDown-mobile .react-countdown .digit {
    font-size: 24px !important;
  }
}

// for tablet
@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .dec2022-countDown {
    max-width: 70%;
    .dec2022-countDown-row .dec2022-react-countdown li {
      padding: 0 14px;
      margin: auto;
    }
    .dec2022-countDown-row {
      .dec2022-timer-date {
        font-size: 3vw;
      }
      .dec2022-timer-title {
        font-size: 1.5vw;
      }
    }
    .dec2022-countDown-row .dec2022-react-countdown {
      .dec2022-digit {
        font-size: 3vw;
      }
      .dec2022-text {
        font-size: 1.6vw;
      }
    }
    // .dec2022-countDown-row .dec2022-react-countdown li:after {
    //   top: -18px;
    //   right: -6px;
    // }
  }
}
