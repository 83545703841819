$speakersHeadingColor: #ffffff;
$borderBottomColor: #ffffff;
$white: #fff;
$titlebg: linear-gradient(90deg, #2D98C7 0%, #00356E 100%);
$subGrey: #606060;
// background: linear-gradient(90deg, #2D98C7 0%, #00356E 100%);
.speakers {
  background-color: rgba(11, 71, 139, 0.32);
}
.speaker-card-wrap {
  width: 300px;
  flex-direction: column;
  margin-top: 8rem;
  border-radius: 18px;
  display: flex
;
  align-items: center;
  position: relative;
  z-index: 0;
}

.speaker-img-wrap {
  position: absolute;
  top: -80px;
  border-radius: 50%;
  padding: 12px;
  filter: drop-shadow(0px 3.124px 2.12px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(2px);
  background-color: rgba(11, 71, 139, 0.32);
  z-index: 100;
}

.speaker-img-wrap img {
  height: fit-content;
  border-radius: 50%;
  cursor: pointer;
  height: 140px;
}

.speaker-details {
  padding-top: 90px !important;
  text-align: center;
  z-index: 50;
  height: 215px;
  width: 240px;
  display: flex;
  cursor: pointer;
flex-direction: column;
    justify-content: center;
  position: relative;
  color: white;
  background: linear-gradient(180deg, #2D98C7 0%, #06468D 100%);
  font-family: "Segoe UI";
  border-radius: 15px;
  padding: 20px 10px;
}

.speaker-details h1 {
  font-size: 20px;
  font-weight: 600 !important;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.speaker-details p {

    color: #FFF;
    text-align: center;
    font-family: "Segoe UI";
    font-size: 17.961px !important;
    font-style: normal;
    font-weight: 400 !important;
    text-transform: capitalize;
}



.speaker-details .designation {
  font-weight: 600;
  font-size: 14px;
}

.speaker-social-icons a , .speaker-social-icons button{
  padding: 0;
}
.speaker-social-icons {
  position: relative;
  z-index: 1;
  height: 80px;
  width: 240px;
  border-radius: 18px;
  display: flex
;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  margin-top: -38px;
  padding: 10px;
  background: white;
}

.speaker-social-icons img {
  height: 24px;
  width: 24px;
}

.speaker-social-icons .MuiIconButton-root {
  color: inherit;
}

.dec-2023 {
  .speakersHeadingBox {
    padding-top: min(15%, 8rem);
  }
  .speakersHeading {
    color: $speakersHeadingColor;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 1px solid $borderBottomColor;
  }
  .keynote-box {
    padding-block: 5rem;
  }

  .speaker-grid {
    gap: 5rem;
    row-gap: 8rem;
    padding-bottom: 10rem;
  }

  .img-wrapper {
    width: 30rem;
    height: 30rem;
    img{
      object-fit: cover;
      height: 30rem;
      object-position: top;
    }
  }
  .card {
    max-width: min-content;
    background-color: transparent;
    overflow: hidden;

    &-title {
      &-container {
        min-height: 7rem;
        background: $titlebg;
      }
      // padding: 2rem;
      color: $white;
      font-size: 2.4rem;
    }
    &-container {
      background-color: $white;
      height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0.8rem;
      align-items: flex-start;
    }
    &-container > p {
      max-width: 100%;
    }
    &-container > p:last-child {
      padding-bottom: 0;
    }
    &-subtitle {
      padding: 0.2rem 1.5rem;
      color: $subGrey;
      font-size: 2rem;
    }
    &-social {
      padding-inline: 0.5rem;
    }
    &-social-img {
      width: 4rem;
      padding: 0.5rem;
      font-size: 4rem;
    }
    &-social-icon {
      font-size: 4rem;
    }
  }
}

@media screen and (max-width: 600px){
  .speaker-card-wrap {
    transform: scale(1.15);
}
.dec-2023 .speaker-grid {
  gap: 5rem;
  row-gap: 12rem;
  padding-bottom: 10rem;
}
.speaker-details p {
  color: #FFF;
  text-align: center;
  font-family: "Segoe UI";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  text-transform: capitalize;
}
.dec-2023 .speakersHeadingBox {
  padding-top: min(15%, 8rem);
  margin-bottom: 5rem;
}

}
