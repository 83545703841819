$base-color-blue: #07b3b3;

.dec2022-speaker {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 15rem;
}

.dec2022-agenda {
  text-align: center;
  background-color: rgba(245, 246, 246, 0.56);
  cursor: default;
  .dec2022-agenda-heading {
    padding: 5rem 0 4rem 0;
    color: #636363;
    font-size: 40px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: 20px;
      width: 80px;
      height: 2px;
      background-color: #68b993;
    }
  }
  .dec2022-agenda-para-text-1,
  .dec2022-agenda-para-text-2 {
    line-height: 1.5;
    font-size: 20px;
  }
  .dec2022-track-list {
    padding-top: 4rem;
    .dec2022-tracks {
      color: #fff;
      padding: 0 2rem;

      .dec2022-track-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        // background-color: rgb(65, 178, 241);
        background-color: rgba(63, 105, 129, 0.1);
        color: rgba(54, 51, 51, 1);
        padding: 1rem 0rem 1.4rem 0rem;
        cursor: pointer;
      }
      .dec2022-track-head {
        font-size: 36px;
        letter-spacing: 2px;
        display: block;
        .dec2022-track-head-span {
          font-size: 1.6rem;
        }
      }
      .dec2022-track-date {
        display: block;
        height: 26px;
        font-size: 15px;
      }
      .dec2022-track-span {
        display: block;
        font-size: 15px;
      }
    }
  }
  .dec2022-trackdata {
    padding: 0 2rem 0rem 2rem;
  }

  .dec2022-trackdata-bg:last-child {
    border: none;
  }
  .dec2022-trackdata-bg {
    border-bottom: 1px solid #dddddd;
    box-shadow: -4px -2px 10px 4px #0000001a;
    padding: 1.6rem 0.6rem;
    margin: 1.5rem 0;
    background-color: #fff;
    .dec2022-event-time {
      font-size: 20px;
      opacity: 1;
    }
    .dec2022-event-desc {
      font-size: 20px;
      text-align: left;
      line-height: 1.8;
      padding: 15px 25px;
      font-weight: 500;
      letter-spacing: 0rem;
      color: #000;
    }
    .dec2022-speaker-head {
      font-size: 1.4rem;
      opacity: 1;
      color: #363333;
      padding-bottom: 20px;
    }
    .dec2022-event-teams {
      display: inline-block;

      a {
        text-decoration: none;
      }

      .dec2022-join-here-head {
        font-size: 1.4rem;
        opacity: 100%;
        color: #363333;
        padding-bottom: 20px;
      }
      .dec2022-teams-image {
        height: 60px;
      }
      .dec2022-teams-img__description {
        color: #fff;
        font-size: 20px;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
.dec2022-agenda-speaker-img {
  // display: inline-block;
  cursor: pointer;
  height: 70px;
  width: 70px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
  }
}
.dec2022-agenda-teams-img {
  // display: inline-block;
  cursor: pointer;
  height: 70px;
  width: 70px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
.dec2022-agenda-speaker-name {
  display: block;
  text-align: center;
  padding-top: 10px;
  font-size: 16px;
  line-height: 1.8;
}

.dec2022-speaker-wrapper-teams {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dec2022-selected-track {
  background-color: #309797 !important;
  color: #fff !important;
}

// for only Desktop
@media only screen and (min-width: 1025px) {
  .dec2022-agenda {
    padding: 0 8% 5rem 8%;
  }
}

// for only Mobile
@media only screen and (max-width: 767px) {
  .dec2022-agenda {
    padding: 15px;
    .dec2022-trackdata-bg {
      text-align: left;
      .dec2022-event-time {
        font-size: 20px;
      }
      .dec2022-event-teams {
        padding-top: 60px;
        text-align: center;
      }
      .dec2022-event-desc {
        font-size: 20px;
        padding: 20px 0 0 0;
      }
      .dec2022-event-speakers {
        font-size: 24px;
        font-weight: 600;
        padding: 40px 0 0 0;
        .dec2022-speaker-head {
          font-weight: 700;
          text-align: center;
        }
        span.dec2022-agenda-speaker-name {
          font-size: 25px !important;
        }
      }
    }
    .dec2022-track-list {
      padding-top: 60px;
      .dec2022-track-row {
        padding: 0;
        justify-content: center !important;
      }
      .dec2022-tracks {
        padding: 0px;
        margin: 0px;
        font-size: 10px;
        .dec2022-track-data {
          padding: 10px 0px;
          .dec2022-track-head {
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 1px;
          }
          .dec2022-track-span {
            font-size: 12px;
          }
        }
      }
    }
    .dec2022-trackdata {
      padding: 15px 0;
    }
    .dec2022-speaker-wrapper-teams {
      float: left;
    }
    .dec2022-event-teams {
      padding: 0 !important;
    }
  }
}
//for ipad pro only
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .dec2022-agenda {
    .dec2022-trackdata-bg .dec2022-event-time {
      text-align: left;
      padding: 0 0 0 25px;
    }
    .dec2022-event-speakers {
      padding: 40px 0;
    }
    .dec2022-track-list .dec2022-tracks .dec2022-track-head {
      font-size: 28px;
    }
  }
}

//for ipad pro only
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .dec2022-agenda {
    padding: 0;
  }
}

.dec2022-speaker1,
.dec2022-speaker2,
.dec2022-speaker3 {
  display: inline-block;
  padding: 10px;
  .dec2022-img-loading {
    animation: flashAnimation 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    opacity: 0;
  }
}

@keyframes flashAnimation {
  0% {
    background-color: #fff;
    opacity: 0;
  }
  50% {
    background-color: #ccc;
    opacity: 1;
  }
  100% {
    background-color: #fff;
    opacity: 0;
  }
}
