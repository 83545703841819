.mobileNavParent {
  height: 100vh !important;
  background-color: #032952;
}
.navButton {
  font-size: 4rem !important;
}
.navText {
  font-size: 1.7rem !important;
  text-transform: uppercase;
  min-width: max-content;
}
.css-17hqp5k-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: blue !important;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 3.5rem;
}

@media screen and (max-width: 1024px) {
  
  #eventTeam{
    display: flex;
    flex-direction: column;
  }
}