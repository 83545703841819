.dec2022-eventTeam {
  text-align: center;
  padding: 0 1rem 4rem 1rem;
  background-color: #fff;
  .dec2022-eventTeam-heading {
    padding-top: 4rem;
    font-size: 40px;
    color: #636363;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: -40px;
      width: 80px;
      height: 2px;
      background-color: #20409b;
    }
  }
  .dec2022-event-organizers {
    padding: 4rem 0;
  }
  .dec2022-organizer-image {
    border-radius: 50%;
    height: 250px;
    width: 250px;
    object-fit: cover;
    object-position: top;
    margin: auto;
  }
  .dec2022-organizer-name {
    padding-top: 20px;
    font-size: 22px;
    padding-bottom: 20px;
  }
  /* .dec2022-eventSquare{
    box-shadow: 5px 10px 8px #888888;
    margin: 15px;
  } */
  .dec2022-card-linkedIn {
    margin-bottom: 10px;
    margin: auto;
  }
}
