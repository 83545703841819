$base-color-black: #135858;
$base-digit-purple: #135858;
.dec-2023 {
  .countdownTimer {
    padding-bottom: 5rem;
    margin-top: 10rem;
  }

  .timerCard {
    padding: 2rem;
    border-radius: 3.5rem !important;
    background: linear-gradient(90deg, #2D98C7 0%, #00356E 100%);
    box-shadow: 0 4px 10px 2px rgb(0 0 0 / 10%) !important;
  }

  .countDownEventDate {
    color: white;
    font-weight: 600 !important;
    font-size: 3.5rem !important;
  }

  .eventText {
    color: white;
    letter-spacing: 0.2em;
    font-weight: 400 !important;
    font-size: 2rem !important;
  }

  .countdownNumbers {
    background: linear-gradient(319.35deg, #217dde 15.11%, #24a1e8 97.82%),
      #10302e;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    // text-fill-color: transparent;
    font-weight: 600 !important;
    font-size: 6rem !important;
  }

  .digits {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    // text-fill-color: transparent;
    line-height: 1.4 !important;
    font-weight: 600 !important;
    font-size: 5rem;
    padding-inline: 1rem;
  }

  .digitDots {
    margin-top: -2rem !important;
    color: white;
    line-height: 1.4 !important ;
    font-weight: 600 !important;
    font-size: 5rem !important;
  }

  .dateUnits {
    font-size: 1.7rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    // text-fill-color: transparent;
    text-transform: capitalize !important;
    letter-spacing: 3px !important;
  }
}

@media (max-width: 851px) {
  .dec-2023 {
    .countDownEventDate,
    .eventText {
      color: #ffffff;
    }
  }
}
@media (max-width: 768px) {
  .dec-2023 {
    .digits,
    .digitDots {
      font-size: 2.5rem !important;
    }
    .dateUnits {
      font-size: min(1.5rem, 5vw);
      letter-spacing: 2px !important;
    }
    .timerCard {
      padding: 0.8rem;
    }
  }
}
