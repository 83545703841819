.dec2022-cursor-none {
  cursor: initial;
}
.dec2022-cursor-click {
  cursor: pointer;
}
.dec2022-keynote,
.dec2022-speakers {
  padding: 2rem 15%;
}
.dec2022-speakers {
  background-color: rgba(245, 246, 246, 0.56);
}
.dec2022-keynote-heading,
.dec2022-speaker-heading {
  font-size: 2.5rem;
  color: #636363;
  margin: 4rem 0;
  text-transform: uppercase;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -40px;
    bottom: -40px;
    width: 80px;
    height: 2px;
    background-color: #2c4fb4;
  }
}
h3 {
  font-size: 2.125rem;
}
.dec2022-keynote-card {
  border: none;
  height: 100%;
  .card-body {
    background-color: rgba(186, 203, 254, 0.22);

    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .dec2022-card-subtitle {
      white-space: pre-line;
    }
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 22px;
      margin-top: 10px;
      font-weight: 500;
    }
    .dec2022-card-linkedIn {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
  .dec2022-social-media-array {
    padding-top: 20px;
    display: flex;
    flex-wrap: nowrap;
    a,
    div {
      padding-right: 20px;
    }
  }
}
.dec2022-speaker-card-wrapper {
  padding: 24px;
}
.dec2022-speaker-card {
  border: none;
  height: 100%;
  .dec2022-card-title-wrapper {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a2f4ee3;

    cursor: pointer;
  }
  .dec2022-card-title {
    color: #ffffff;
    font-size: 2rem;
  }
  .card-body {
    // background-color: rgb(116, 134, 199) !important;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    p {
      font-size: 1.5rem;
      white-space: pre-line;
    }

    .dec2022-card-text {
      font-weight: 600;
    }
    .dec2022-social-media-array {
      display: flex;
      flex-wrap: nowrap;
      padding-top: 20px;
      a {
        padding-right: 20px;

        .dec2022-card-socialmedia {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
      }
      div {
        padding-right: 20px;
        .dec2022-card-socialmedia {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
      }
    }
  }
}
.dec2022-nopadding {
  padding: 0 !important;
}
.dec2022-LazyLoad {
  margin: auto;
}
.dec2022-keynote-image img {
  object-fit: cover;
  height: 100%;
  object-position: top;
  width: 100%;
}

.dec2022-speaker-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.dec2022-speaker-image {
  position: relative;
  cursor: pointer;
}
.dec2022-speaker-image img {
  object-fit: cover;
  height: 100%;
  object-position: top;
  width: 100%;
}

.dec2022-img-loading {
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
}

.dec2022-img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

#dec2022-cube {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
}
.dec2022-keynote-image #dec2022-cube {
  position: absolute;
  top: 30%;
  left: 50%;
}
.dec2022-square {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #4b9cdb;
  animation: loadingG 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
@keyframes loadingG {
  0% {
    transform: translate(-120px, 0) rotate(0deg);
  }
  50% {
    transform: translate(70px, 0) rotate(360deg);
  }
  100% {
    transform: translate(-120px, 0) rotate(0deg);
  }
}
@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1750px) {
  .dec2022-keynote,
  .dec2022-speakers {
    padding: 2rem 10%;
  }
}
@media only screen and (max-width: 768px) {
  .dec2022-keynote  .keyNoteRow{
    flex-direction: column;
  }
  .dec2022-head-data .dec2022-content-right {
    padding-top: 124px !important;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}
}
@media only screen and (max-width: 576px) {
  .dec2022-keynote,
  .dec2022-speakers {
    padding: 2rem 0;
  }

}
