$base-color-blue: #0a2f4e;

.dec2022-cfs {
  text-align: center;
  padding: 0 1rem 4rem 1rem;
  background-color: rgba(245, 246, 246, 0.56);
  .dec2022-cfs-heading {
    padding-top: 4rem;
    font-size: 40px;
    color: #636363;
    position: relative;
    // padding-bottom: 15px;
    // border-bottom-style: solid;
    // border-bottom-width: 3.dec2022-1px;
    // width: fit-content;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: -40px;
      width: 80px;
      height: 2px;
      background-color: $base-color-blue;
    }
  }

  .dec2022-cfs-para-text {
    padding-top: 5rem;
    line-height: 1.9rem;
    font-size: 1.3rem;
    span {
      a {
        color: $base-color-blue;
      }
    }
  }
  .dec2022-cfs-text {
    padding-top: 5rem;

    .dec2022-cfs-button {
      padding: 15px 5%;
      //Call for Speaker Button Color
      background-color: $base-color-blue;
      border-radius: 40px;
      text-decoration: none;
      display: inline-block;
      // transition: all 0.3s ease-in-out;
      // cursor: pointer;

      // &:hover {
      //   transform: scale(1.05);
      //   box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
      // }
    }
    p {
      color: white;
      font-size: 2rem;
      margin: 0;
    }
  }
}
@keyframes background1 {
  from {
    background-color: $base-color-blue;
  }
  to {
    background-color: #fff;
  }
}

// for only Desktop
@media only screen and (min-width: 1025px) {
  .dec2022-cfs {
    padding: 0 15% 4rem 15%;
  }
}
