.dec-2023 {
  .eventInfo {
    background: linear-gradient(180deg, #2D98C7 0%, #06468D 100%);
    margin-top: min(15%, 8rem);
  }

  .eventInfoSubtext {
    font-weight: 500 !important;
  }
}
