$sponsorHeadingColor: white;
$borderBottomColor: #ffffff;
$diamondColor: #5eb3d8;
$platinumColor: #9653ca;
$goldColor: #eaaf0e;

.dec-2023 {
  .sponsorsHeadingBox {
    padding-top: min(15%, 8rem);
  }
  .sponsor-card img{
    background-color: white;
    border-radius: 8px;
    height: 146px;

  }
  .sponsorHeading {
    color: $sponsorHeadingColor;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 1px solid $borderBottomColor;
  }
  .sponsorSubHeading {
    width: fit-content;
    margin: 0 auto;
  }
  .diamond {
    color: $diamondColor;
    border-bottom: 5px solid $diamondColor;
  }
  .platinum {
    color: $platinumColor;
    border-bottom: 5px solid $platinumColor;
  }
  .gold {
    color: $goldColor;
    border-bottom: 5px solid $goldColor;
  }
  .learningPartner {
    color: white;
    border-bottom: 5px solid white;
  }

  .sponsor-wrapper {
    margin-top: 5rem;
  }
  .sponsor-border {
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.25);
    border-radius: 7px;
  }
  .sponsor-img {
    object-fit: contain;
    margin: auto;
    padding: 5rem;
  }
  .learningPartner-img {
    object-fit: contain;
    margin: auto;
    padding: 1rem 0 0 0;
  }
}

#sponsors{
  margin-top: "5rem"
}
.sponsors{
  background: #032952;
}

@media only screen and (max-width: 515px) {
  .dec-2023{
    .promoPartners{
      max-width: 25rem;
    }
  }
}