html {
  font-size: 100%;
}
*,
.dec2022-container-fluid,
h1,
.dec2022-row {
  padding: 0;
  margin: 0;
}

body {
  color: #606060;
}
$base-color: #a1191d;
