.dec2022-QuizPrizes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  .dec2022-QuizPrizes-wrapper {
    display: flex;
    width: 75%;
    :hover {
      transition: all 0.1s ease-in-out;
      transform: scale(1.005);
    }
  }
  .dec2022-QuizPrizes-wrapper2 {
    display: flex;
    width: 50%;
    :hover {
      transition: all 0.1s ease-in-out;
      transform: scale(1.005);
    }
  }

  .dec2022-QuizPrizes-card {
    padding: 1rem;
  }
  .dec2022-QuizPrizes-card-img {
    width: 100%;
  }
  .dec2022-QuizPrizes-heading {
    position: relative;
    text-transform: uppercase;
    font-size: 40px;
    color: #636363;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -40px;
      bottom: -40px;
      width: 80px;
      height: 2px;
      background-color: #68b993;
    }
  }
  .dec2022-QuizPrizes-para-wrapper {
    width: 100%;
    text-align: center;
  }
  .dec2022-QuizPrizes-para-text {
    line-height: 1.9rem;
    font-size: 1.3rem;
  }
  .dec2022-QuizPrizes-card-head {
    position: relative;
  }

  .dec2022-QuizPrizes-card-heading {
    position: absolute;
    display: flex;
    place-items: center;
    place-content: center;
    bottom: 0;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.045em;
    width: 100%;
    height: 2.5rem;
    // background: linear-gradient(325.49deg, #4faaaa 12.41%, #064949 100.31%);
    background-color: #294774;
  }
  .dec2022-QuizPrizes-card-desc {
    font-size: 1.5rem;
    min-height: 5rem;
    display: flex;
    place-items: center;
    place-content: center;
    font-weight: 400;
    text-align: center;
    font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.4167)), 1.5rem);
    color: #294774;
    letter-spacing: 0.045em;
  }
}

.dec2022-QuizPrizes-scroll {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .dec2022-QuizPrizes {
    .dec2022-QuizPrizes-wrapper {
      width: 50%;
    }
    .dec2022-QuizPrizes-wrapper {
      flex-direction: column;
    }
    .dec2022-QuizPrizes-wrapper2 {
      width: 50%;
    }
    .dec2022-QuizPrizes-wrapper2 {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 520px) {
  .dec2022-QuizPrizes .dec2022-QuizPrizes-wrapper {
    width: 100%;
  }
  .dec2022-QuizPrizes .dec2022-QuizPrizes-wrapper2 {
    width: 100%;
  }
}
