$organizerHeadingColor: white;
$borderBottomColor: #ffffff;

.dec-2023 {
  .organizers {
    background-color:  rgba(11, 71, 139, 0.32);
  }
  .organizerHeadingBox {
    padding-top: min(15%, 5rem);
  }
  .organizerSubHeadingBox {
    padding-top: min(10%, 5rem);
  }
  .organizerHeading {
    color: $organizerHeadingColor;
    width: fit-content;
    margin: 0 auto;
    border-bottom: 1px solid $borderBottomColor;
  }
  .organizerSubHeading {
    color: $organizerHeadingColor;
    width: fit-content;
    margin: 0 auto;
  }
  .organizersCard {
    padding-inline: 4rem;
    row-gap: 5rem;
    column-gap: 10rem;
    max-width: 120rem;
    margin-inline: auto;
  }
}
